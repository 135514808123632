'use client'

import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { v4 } from 'uuid'
import * as Tone from 'tone'
import { Howl, SoundSpriteDefinitions } from 'howler'

import { usePlayerStore } from '../lib/playerStore'
import { transposeTrack } from '../lib/utils'
import { Sample, SongData } from '../types'
import { useLooper } from './looper-provider'

// ————— types —————

export type Track = {
  id: string
  src: string
  player: Howl
  data: SongData[] | Sample[]
  title: string
  isSoloed?: boolean
  isMuted?: boolean
  pitchShiftNode?: Tone.PitchShift
  isRemixTrack?: boolean
}

type TracksContextState = {
  tracks: Track[]
  setTracks: Dispatch<React.SetStateAction<Track[]>>
  playAll: () => void
  pauseAll: () => void
  seekAll: (time: number) => void
  stopAll: () => void
  toggleMuteTrack: (track: Track) => void
  toggleSoloTrack: (track: Track) => void
  getPlayer: (
    src: string,
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ) => Howl
  addNewTrack: (
    // TODO: clean up data shape between this a Sample
    songData: SongData[],
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean,
    isRemixTrack?: boolean
  ) => Promise<Track | void>
  deleteTrack: (track: Track) => void
  updateBpm: () => void
  updateKey: (prevKey: string, nextKey: string) => Promise<void>
  lockedDuration?: number
  setLockedDuration: Dispatch<SetStateAction<number | undefined>>
  allTracksLoaded: boolean
  setAllTracksLoaded: Dispatch<SetStateAction<boolean>>
}

// ————— context —————
const defaultState: TracksContextState = {
  tracks: [],
  setTracks: () => {},
  playAll: () => {},
  pauseAll: () => {},
  seekAll: () => {},
  stopAll: () => {},
  toggleMuteTrack: () => {},
  toggleSoloTrack: () => {},
  getPlayer: () => new Howl({ src: [] }),
  addNewTrack: async (
    _songData: SongData[] | Sample[],
    _title: string,
    _currentBpm?: number,
    _targetBpm?: number,
    _duration?: number,
    _isPreview?: boolean,
    _isRemixTrack?: boolean
  ) => {},
  deleteTrack: () => {},
  updateBpm: async () => {},
  updateKey: async () => {},
  lockedDuration: undefined,
  setLockedDuration: () => {},
  allTracksLoaded: false,
  setAllTracksLoaded: () => {}
}
const TracksContext = createContext<TracksContextState>(defaultState)

// ————— provider —————

export const TracksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // ————— local state —————

  const { bpm, currentBpm } = useLooper()
  const setIsPlaying = usePlayerStore((state) => state.setIsPlaying)
  const [tracks, setTracks] = useState<Track[]>([])
  const [lockedDuration, setLockedDuration] = useState<number>()
  const [allTracksLoaded, setAllTracksLoaded] = useState(false)

  // ————— effects —————

  useEffect(() => {
    setTracks((prevTracks) => {
      const anySoloed = prevTracks.some((t) => t.isSoloed)
      prevTracks.forEach((track) => {
        if (!track.player) return

        if (anySoloed) {
          if (!!track.isSoloed) {
            track.player.mute(!!track.isMuted)
          } else {
            track.player.mute(true)
          }
        } else {
          track.player.mute(!!track.isMuted)
        }
      })
      return prevTracks
    })
  }, [tracks])

  const setRateIfNeeded = (track: Track, id?: number) => {
    if (!track.player) {
      return
    }
    if (!currentBpm) {
      return
    }

    // @ts-ignore
    const nextRate = currentBpm / track.data[0].bpm

    if (track.player.rate() === nextRate) {
      return
    }

    if (id) {
      track.player.rate(nextRate, id)
    } else {
      track.player.rate(nextRate)
    }
  }

  const updateBpm = useCallback(() => {
    tracks.forEach((track) => {
      if (!track.player) return

      // @ts-ignore
      const sounds = track.player._sounds
      // @ts-ignore
      if (track.player._sprite[track.title] && sounds.length > 1) {
        const id = sounds.findLast((s: any) => s._sprite === track.title)?._id
        if (id) setRateIfNeeded(track, id)
      }
      try {
        setRateIfNeeded(track)
      } catch (error) {
        console.error('Error setting rate for track:', error)
      }
    })
  }, [tracks, currentBpm]) // Add currentBpm as a dependency

  const updateKey = async (currentKey: string, nextKey: string) => {
    tracks.forEach((track) => transposeTrack(track, currentKey, nextKey))
  }

  // ————— controls for all tracks —————

  const playAll = useCallback(() => {
    setTracks((prevTracks) => {
      prevTracks.forEach((track) => {
        // Skip if track was deleted but state hasn't updated yet
        // if (!track.player) return

        // @ts-ignore
        if (track.player._sprite[track.title]) {
          // @ts-ignore
          const sounds = track.player._sounds
          if (sounds.length > 0) {
            let id
            if (sounds.length === 1) {
              id = sounds[0]._id
            } else {
              id = sounds.findLast((s: any) => s._sprite === track.title)?._id
            }

            try {
              if (id) {
                track.player.play(id)
                // @ts-ignore
                if (currentBpm) setRateIfNeeded(track, id)
              }
            } catch (error) {
              track.player.play(track.title)
              setRateIfNeeded(track)
            }
          }
        } else {
          track.player.play()
          setRateIfNeeded(track)
        }
      })
      return prevTracks
    })
    setIsPlaying(true)
  }, [setRateIfNeeded])

  const pauseAll = useCallback(() => {
    setTracks((prevTracks) => {
      prevTracks.forEach((track) => {
        if (!track.player) return
        track.player.pause()
      })
      return prevTracks
    })
    setIsPlaying(false)
  }, [])

  const seekAll = useCallback((time: number) => {
    setTracks((prevTracks) => {
      prevTracks.forEach((track) => {
        if (!track.player) return
        track.player.seek(time)
      })
      return prevTracks
    })
  }, [])

  const stopAll = useCallback(() => {
    setTracks((prevTracks) => {
      prevTracks.forEach((track) => {
        // Skip if track was deleted but state hasn't updated yet
        if (!track.player) return
        track.player.stop()
      })
      return prevTracks
    })
    setIsPlaying(false)
  }, [])

  // ————— controls for individual tracks —————

  const deleteTrack = useCallback((track: Track) => {
    setTracks((prevTracks) => {
      // First find and cleanup the track to be deleted
      const trackToDelete = prevTracks.find((t) => t.id === track.id)
      if (trackToDelete?.player) {
        trackToDelete.player.stop()
        trackToDelete.player.unload()
        // @ts-ignore
        trackToDelete.player = null
        // @ts-ignore
        delete trackToDelete.player
        if (trackToDelete.pitchShiftNode) trackToDelete.pitchShiftNode.dispose()
      }
      // Then filter it out
      return prevTracks.filter((t) => t.id !== track.id)
    })
  }, [])

  const toggleMuteTrack = useCallback((track: Track) => {
    setTracks((prevTracks) =>
      prevTracks.map((t) => {
        if (!t.player) return t // Skip if track was deleted
        return t.id === track.id ? { ...t, isMuted: !t.isMuted } : t
      })
    )
  }, [])

  const toggleSoloTrack = useCallback((track: Track) => {
    setTracks((prevTracks) =>
      prevTracks.map((t) => {
        if (!t.player) return t // Skip if track was deleted
        return t.id === track.id ? { ...t, isSoloed: !t.isSoloed } : t
      })
    )
  }, [])

  const getPlayer = (
    src: string,
    title: string,
    sourceBpm: number,
    targetBpm: number,
    duration?: number,
    isPreview?: boolean
  ) => {
    // TODO: if there is a currentBPM set, the calculation for newly added samples is incorrect.
    const playbackRate = targetBpm / sourceBpm
    // @ts-ignore
    let player

    const getSprite = (title: string, duration: number, isPreview?: boolean): SoundSpriteDefinitions => ({
      [title]: [0, duration, !isPreview]
    })

    if (duration) {
      // newDuration always needs to be based on the set bpm (of the first added track) otherwise,
      // if it is calculated based off of currentBpm, new durations will be out of sync
      const newDuration = duration * (bpm / sourceBpm)
      const newSprite = getSprite(title, newDuration, isPreview) as SoundSpriteDefinitions
      player = new Howl({
        src: [src],
        sprite: newSprite,
        volume: 0.5,
        rate: playbackRate,
        preload: true,
        loop: true,
        onload: () => {
          if (!isPreview) {
            setAllTracksLoaded(
              tracks.every((t) => {
                // There is a case where we've deleted a track but are also adding a new track
                // and the old track hasn't been fully removed from tracks array
                if (!t.player) return true
                return t.player.state() === 'loaded'
              })
            )
          }
        },
        onloaderror: (_id, _err) => {
          // @ts-ignore
          player.unload()
          // @ts-ignore
          player.load()
        }
      })
    } else {
      player = new Howl({
        src: [src],
        volume: 0.75,
        rate: playbackRate,
        loop: true,
        onload: function () {
          if (!isPreview) setAllTracksLoaded(tracks.every((t) => t.player.state() === 'loaded'))
        }
      })
    }

    return player
  }

  const addNewTrack = useCallback(
    async (
      songData: SongData[] | Sample[],
      title: string,
      sourceBpm: number,
      targetBpm: number,
      duration?: number,
      isPreview?: boolean,
      isRemixTrack?: boolean
    ): Promise<Track> => {
      stopAll()
      setIsPlaying(false)

      const audioSrc = typeof songData[0].audio === 'string' ? songData[0].audio : ''
      const newTrack: Track = {
        id: v4(),
        src: audioSrc,
        data: songData,
        player: getPlayer(audioSrc, title, sourceBpm, targetBpm, duration, isPreview),
        title,
        isRemixTrack: isRemixTrack ?? false
      }

      await new Promise<void>((resolve) => {
        setTracks((prevTracks) => [...prevTracks, newTrack])
        setTimeout(resolve, 0) // Give state time to update
      })

      return newTrack
    },
    [stopAll]
  )

  // ————— render —————

  return (
    <TracksContext.Provider
      value={{
        tracks,
        setTracks,
        playAll,
        pauseAll,
        seekAll,
        stopAll,
        deleteTrack,
        toggleMuteTrack,
        toggleSoloTrack,
        getPlayer,
        addNewTrack,
        updateBpm,
        updateKey,
        lockedDuration,
        setLockedDuration,
        allTracksLoaded,
        setAllTracksLoaded
      }}
    >
      {children}
    </TracksContext.Provider>
  )
}

// ————— hooks —————
export const useTracks = () => useContext(TracksContext)
