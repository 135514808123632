import { createContext, ReactNode, useContext, useState } from 'react'

interface LooperContextType {
  bpm: number
  currentBpm: number
  setBpm: (bpm: number) => void
  setCurrentBpm: (bpm: number) => void
  key: string
  currentKey: string
  keyLocked: boolean
  setKey: (key: string) => void
  setCurrentKey: (key: string) => void
  setKeyLocked: (newKeyLockedState: boolean) => void
}

interface LooperProviderProps {
  children: ReactNode
  initialBpm?: number
  initialKey?: string
}

const LooperContext = createContext<LooperContextType | null>(null)

export const LooperProvider = ({ children, initialBpm = 120, initialKey = '' }: LooperProviderProps) => {
  const [bpm, setBpm] = useState(initialBpm)
  const [currentBpm, setCurrentBpm] = useState(initialBpm)
  const [key, setKey] = useState(initialKey)
  const [currentKey, setCurrentKey] = useState(initialKey)
  const [keyLocked, setKeyLocked] = useState(false)

  return (
    <LooperContext.Provider
      value={{
        bpm,
        currentBpm,
        setBpm,
        setCurrentBpm,
        key,
        currentKey,
        keyLocked,
        setKey,
        setCurrentKey,
        setKeyLocked
      }}
    >
      {children}
    </LooperContext.Provider>
  )
}

export const useLooper = () => {
  const context = useContext(LooperContext)
  if (!context) throw new Error('useLooper must be used within LooperProvider')
  return context
}
