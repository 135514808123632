'use client'
import { usePathname } from 'next/navigation'
import { useSkin } from '../context/skin-provider'
import { cn } from '../lib/utils'
import { useEffect, useState, useRef, memo } from 'react'

interface SkinBackgroundProps {
  isHidden?: boolean // (don't want to duplicate backgrounds, this is because of how background is structured in _app.tsx, should be cleaned up)
}

const SkinBackground = memo(({ isHidden }: SkinBackgroundProps) => {
  const { skin } = useSkin()
  const pathname = usePathname()
  const [isLoaded, setIsLoaded] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  // Preload the video when the skin changes
  useEffect(() => {
    if (!skin.video) return

    // Reset state when skin changes
    setIsLoaded(false)

    // Create a new video element to preload the video
    const preloadVideo = new Image()

    // Set the video URL only after preloading
    preloadVideo.onload = () => {
      setVideoUrl(skin.video)
    }

    // Use a small poster image from the same source to preload
    preloadVideo.src = skin.image || ''

    // Fallback in case image preloading fails
    const timer = setTimeout(() => {
      if (!videoUrl) {
        setVideoUrl(skin.video)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [skin.video, skin.image])

  // Don't render in admin routes or feed routes
  if (pathname.startsWith('/admin/') || isHidden) return null

  return (
    <>
      {/* Background color fallback - always black */}
      <div className="absolute inset-0 -z-50 h-screen w-screen bg-black" />

      {/* Video background - shown only after loading */}
      {videoUrl && (
        <video
          ref={videoRef}
          className={cn(
            'absolute inset-0 -z-50 h-screen w-screen object-cover transition-opacity duration-500',
            isLoaded ? 'opacity-50' : 'opacity-0'
          )}
          key={videoUrl}
          autoPlay
          muted
          loop
          playsInline
          controls={false}
          onLoadedData={() => setIsLoaded(true)}
          preload="auto"
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </>
  )
})

SkinBackground.displayName = 'SkinBackground'

export default SkinBackground
