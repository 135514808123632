import React, { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { nextTrpc } from '@/src/lib/trpc'
import { Role } from '@prisma/client'
import { UserNav } from './UserNav'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'

export default function Layout({ children }: { children: ReactNode }) {
  const router = useRouter()
  const pathname = router.pathname
  const searchParams = useSearchParams()
  const isEmbedded = searchParams.get('embedded') !== null

  const { data: me } = nextTrpc.user.me.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false
  })

  return (
    <div>
      {!isEmbedded && (
        <div className="border-bottom-1 absolute top-0 z-30 w-full bg-black p-2 text-center text-xs text-white">
          {!pathname.includes('/admin/') && (
            <a href="#tally-open=woBXD1&" className="underline">
              JOIN THE WAITLIST 👀
            </a>
          )}

          {me && isCorrectRole('logged-in', me) ? (
            <div className="absolute right-2 top-2 cursor-pointer hover:underline">
              <UserNav me={me} />
            </div>
          ) : (
            <Link className="absolute right-2 top-2 hover:underline" href="/login">
              Login
            </Link>
          )}
        </div>
      )}
      <div className="h-full w-full overflow-auto">{children}</div>
    </div>
  )
}

type Roles = 'logged-in' | 'logged-out' | 'on-team' | 'superadmin'
const isCorrectRole = (role?: Roles, me?: { role: Role } | null) => {
  if (!role) return true
  if (role === 'logged-in') return !!me
  return false
}
